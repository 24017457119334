"use client";

import { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { BoxProps, Collapse, Link, Stack, Typography, TypographyProps } from "@mui/material";

import { MhcAttributionFragment } from "graphqlApi/types";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";

export interface StatAttributionProps {
  attribution: Omit<MhcAttributionFragment, "__typename">;
  component: BoxProps["component"];
  titleFontWeight?: TypographyProps["fontWeight"];
}

export const StatAttribution: React.FC<StatAttributionProps> = ({
  attribution,
  component,
  titleFontWeight = 400,
  ...props
}) => {
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
  const { url, name, citation, description } = attribution;
  return (
    <Stack gap={0.5}>
      <Typography component="span" fontWeight={titleFontWeight}>
        {name}
      </Typography>
      {description && (
        <>
          <Link
            className="link-with-underline"
            variant="body2"
            onClick={() => setDescriptionOpen((prev) => !prev)}
            component="button"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography component="span" variant="body2">
              Description or Additional Information
            </Typography>
            {descriptionOpen ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </Link>
          <Collapse in={descriptionOpen} timeout="auto" unmountOnExit>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {/*
                The description comes from a trusted source, we are the only ones
                who can update those descriptions. I don't think we need to clean
                it, but let me know if you feel like we should
              */}
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </Typography>
          </Collapse>
        </>
      )}
      {citation && (
        <Stack direction="row" sx={{ alignItems: "baseline" }} {...props}>
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            <Typography component="span" variant="body2" fontWeight={600}>
              Citation:{" "}
            </Typography>
            {citation}
          </Typography>
        </Stack>
      )}
      {url && (
        <Stack direction="row" sx={{ alignItems: "baseline" }} {...props}>
          <Link
            href={url}
            target="_blank"
            className="link-with-underline"
            rel="noopener noreferrer"
            variant="body2"
            title={`View additional information about and/or download ${name} dataset`}
            aria-label={`View or download ${name} dataset`}
            onClick={() =>
              sendGaNavigationEvent({
                category: "Data Download",
                action: "About the data click",
                label: name,
                ui_location: window.location.pathname
              })
            }
          >
            View or download this dataset
          </Link>
        </Stack>
      )}
    </Stack>
  );
};
